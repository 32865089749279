import React, { useEffect } from 'react';
import Header from './Header/Header';
import { submissionRoute } from './Content/UserDeveloper/Submission/SubmissionRoute';
import { AuthenticateVersionOptions } from './Content/Authenticate/Authenticate';
import AuthenticatedRoute from './Session/AuthenticatedRoute';
import CompletedProfileRoute from './Session/CompletedProfileRoute';
import PreviewRoute, { PREVIEW_ROUTE } from './Content/Preview/PreviewRoute';
import { Route, RouteComponentProps, Switch, useHistory, useLocation } from 'react-router-dom';
import { permanentMenuWidth } from './theme';
import CzyLoader from '../../common/Loader';
import NoticeSnackbarLoader from './Content/NoticeSnackbar/NoticeSnackbarLoader';
import SessionContext from '../../common/Session/SessionContext';
import LandingPage from '../App/Content/LandingPage/LandingPage';
import { Box } from '@mui/material';
import { DOCS_URL } from './SideMenu/Menu';

const ProfileLoader = React.lazy(() => import('./Content/Profile/ProfileLoader'));
const ProfileBilling = React.lazy(() => import('./Content/Profile/Billing'));
const Admin = React.lazy(() => import('./Content/Admin/Admin'));
const CopyWriter = React.lazy(() => import('./Content/Copywriter/CopyWriter'));
const UserDeveloper = React.lazy(() => import('./Content/UserDeveloper/UserDeveloper'));

const NewSubmissionLoader = React.lazy(() => import('./Content/UserDeveloper/Submission/NewSubmissionLoader'));
const Assets = React.lazy(() => import('./Content/Assets/Assets'));
const Support = React.lazy(() => import('./Content/Support/Support'));
const BlogLoader = React.lazy(() => import('./Content/BlogPosts/BlogPostsLoader'));
const BlogPostLoader = React.lazy(() => import('./Content/BlogPosts/BlogPostLoader'));
const Authenticate = React.lazy(() => import('./Content/Authenticate/Authenticate'));
const PasswordReset = React.lazy(() => import('./Content/Authenticate/PasswordReset'));
const EmailVerification = React.lazy(() => import('./Content/Authenticate/EmailVerification'));
const DisplayNamePicker = React.lazy(() => import('./Content/Authenticate/DisplayNamePicker'));
const VerifyContactEmail = React.lazy(() => import('./Content/Profile/VerifyContactEmail'));
const QAContinue = React.lazy(() => import('./Content/UserDeveloper/QATool/QAContinue/QAContinue'));

function BlogPostLoaderRoute(route: RouteComponentProps<{ slug: string }>) {
  return <BlogPostLoader slug={decodeURIComponent(route.match.params.slug)} />;
}

const RedirectTo = (url: string) => {
  window.location.href = url;
  return null;
};

const LayoutWrappedContent: React.FC = () => {
  const session = React.useContext(SessionContext);

  return (
    <React.Suspense fallback={<CzyLoader />}>
      <Box sx={{ ml: { xs: 0, md: session.isLoggedIn() ? `${permanentMenuWidth}px` : 'unset' } }}>
        <Header key="appbar" type="DEFAULT" />
        <div className="app--content" key="app">
          <CompletedProfileRoute
            exact
            path="/qa-continue/:submissionId"
            component={(route: RouteComponentProps<{ submissionId: string }>) => (
              <QAContinue submissionId={route.match.params.submissionId} />
            )}
          />
          <Route exact path="/assets" component={Assets} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/blog" component={BlogLoader} />
          <Route exact path="/blog/:slug" component={BlogPostLoaderRoute} />
          <Route exact path="/verifyContactEmail" component={VerifyContactEmail} />
          <CompletedProfileRoute exact path="/profile" component={ProfileLoader} />
          <CompletedProfileRoute exact path="/profile/billing" component={ProfileBilling} />
          <CompletedProfileRoute exact path="/submit" component={NewSubmissionLoader} />

          {/* redirects to the new documentation */}
          <Route exact path="/quality-guidelines" component={() => RedirectTo(`${DOCS_URL}/requirements/quality/`)} />
          <Route exact path="/unity-export-tips" component={() => RedirectTo(`${DOCS_URL}/resources/export-tips/`)} />
          <Route exact path="/sdk" component={() => RedirectTo(DOCS_URL)} />

          {submissionRoute}
          <CompletedProfileRoute path="/games" component={UserDeveloper} />
          <AuthenticatedRoute path="/admin" component={Admin} />
          <AuthenticatedRoute path="/copywriter" component={CopyWriter} />
          {session.isLoggedIn() && <NoticeSnackbarLoader />}
        </div>
      </Box>
    </React.Suspense>
  );
};

const AppContent: React.FC = () => {
  const session = React.useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (session.isLoggedIn() && location.pathname === '/' && location.hash === '') {
      history.push('/games');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      {/* Switch only looks at top-level <Route> children */}
      {/* catch routes that do not need to be wrapped */}
      <Route exact={true} path={PREVIEW_ROUTE} component={PreviewRoute} />
      <Route exact={true} path="/" component={LandingPage} />
      <Route
        exact={true}
        path="/login"
        render={() => (
          <React.Suspense fallback={<CzyLoader />}>
            <Authenticate version={AuthenticateVersionOptions.Login} />
          </React.Suspense>
        )}
      />
      <Route
        exact={true}
        path="/register"
        render={() => (
          <React.Suspense fallback={<CzyLoader />}>
            <Authenticate version={AuthenticateVersionOptions.Register} />
          </React.Suspense>
        )}
      />
      <Route
        exact={true}
        path="/password-reset"
        render={() => (
          <React.Suspense fallback={<CzyLoader />}>
            <PasswordReset />
          </React.Suspense>
        )}
      />
      <AuthenticatedRoute
        exact
        path="/email-verification"
        render={() => (
          <React.Suspense fallback={<CzyLoader />}>
            <EmailVerification />
          </React.Suspense>
        )}
      />
      <AuthenticatedRoute
        exact
        path="/display-name-picker"
        render={() => (
          <React.Suspense fallback={<CzyLoader />}>
            <DisplayNamePicker />
          </React.Suspense>
        )}
      />
      <Route render={() => <LayoutWrappedContent />} />
    </Switch>
  );
};

export default AppContent;
